import { Box, Grid, Typography } from '@mui/material'
import { navigate } from 'gatsby'
import { toast } from 'react-toastify'
import {
  Address,
  SubMaster,
  useAddSubMasterMutation,
} from 'manage-tritag/services/api/endpoints/sub-masters'
import { useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import LocationPicker from 'manage-tritag/components/location-picker'
import Spinner from 'manage-tritag/components/loading/spinner'
import uploadImage from '../../../images/upload.png'
import {
  FormContent,
  SecondGrid,
  StyledButton,
  StyledButtonDiv,
  StyledGridContainer,
  StyledTextField,
  UploadContainer,
} from './styles'
import { LogoContainer } from '../associations/styles'

const SubMasterCreateForm = () => {
  const [createSubMaster, { isLoading: createLoading }] =
    useAddSubMasterMutation()
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: '',
      primaryContact: '',
      primaryPhone: '',
      primaryEmail: '',
      address: '',
      abn: '',
      logo: '',
    },
  })
  const [address, setAddress] = useState<Address | undefined>(undefined)
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [logo, setLogo] = useState<any>(undefined)

  const handleFileClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click()
    }
  }

  const handleFileChange = (e: any) => {
    setLogo(URL.createObjectURL(e.target.files[0]))
    setSelectedFile(e.target.files[0])
  }

  const onSubmit: SubmitHandler<SubMaster> = async (createData: any) => {
    if (createLoading) return
    const fileData = new FormData()
    const reqData = {
      name: createData.name,
      primaryContact: {
        name: createData.primaryContact,
        email: createData.primaryEmail,
        phone: createData.primaryPhone,
      },
      address,
      abn: createData.abn,
      logo: '',
    }
    fileData.append('formData', JSON.stringify(reqData))
    fileData.append('file', selectedFile!)

    await createSubMaster(fileData).unwrap()
    reset()
    toast.success('Sub master added succesfully.')
    navigate('/sub-masters')
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <StyledGridContainer container spacing={3} justifyContent="space-between">
        <Grid item xs={12} sm={12} md={3}>
          {logo ? (
            <LogoContainer onClick={handleFileClick}>
              <img
                src={logo}
                height="170"
                alt="logo"
                placeholder="blurred"
                style={{
                  height: '180px',
                  width: '180px',
                  objectFit: 'cover',
                }}
              />
            </LogoContainer>
          ) : (
            <UploadContainer onClick={handleFileClick}>
              <Typography
                variant="subtitle2"
                component="p"
                sx={{ marginBottom: '0.8rem', fontWeight: 'bold' }}
              >
                Upload your logo
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: '0.8rem' }}>
                PNG and JPG file
              </Typography>
              <Typography
                variant="subtitle2"
                component="h6"
                sx={{ fontSize: '0.8rem' }}
              >
                format only
              </Typography>
              <img
                height="50"
                src={uploadImage}
                alt=""
                style={{ marginTop: '1rem' }}
              />
            </UploadContainer>
          )}

          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </Grid>

        <SecondGrid item xs={12} sm={12} md={8}>
          <Typography
            sx={{ fontWeight: 'bold', color: '#008174', marginBottom: '1rem' }}
          >
            Sub master details
          </Typography>

          <FormContent>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 1 }}
                  id="filled-basic"
                  label="Name"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <Controller
              name="address"
              control={control}
              render={() => {
                return <LocationPicker tabIndex={2} setAddress={setAddress} />
              }}
            />
          </FormContent>

          <FormContent>
            <Controller
              name="abn"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 3 }}
                  id="filled-basic"
                  label="ABN"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <Controller
              name="primaryContact"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 4 }}
                  id="filled-basic"
                  label="Primary contact"
                  variant="filled"
                  size="small"
                  required
                  {...field}
                />
              )}
            />
          </FormContent>

          <FormContent>
            <Controller
              name="primaryEmail"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 5 }}
                  id="filled-basic"
                  label="Primary contact email"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <Controller
              name="primaryPhone"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 6 }}
                  id="filled-basic"
                  label="Primary contact number"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />
          </FormContent>
        </SecondGrid>
      </StyledGridContainer>
      <StyledButtonDiv>
        <div />

        <div>
          <StyledButton
            variant="outlined"
            onClick={() => {
              navigate('/sub-masters')
            }}
            sx={{
              border: '2px solid #008174',
              color: '#000',
              marginRight: '1.5rem',
            }}
          >
            CANCEL
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: '83px' }}
          >
            {createLoading ? <Spinner size={22} /> : 'SAVE'}
          </StyledButton>
        </div>
      </StyledButtonDiv>
    </Box>
  )
}

export default SubMasterCreateForm
